<template>
    <div id="page-upload">
        <!-- 上方列 -->
        <div class="vx-card p-3 mb-base">
            <vs-row>
                <vs-col :vs-type="windowWidth < 768 ? '' : 'flex'" :vs-justify="activePatientInfo != null ? 'space-between' : 'flex-end'" vs-align="center">
                    <div>
                        <h4 :class="windowWidth < 768 ? 'cd-text-group' : ''" v-if="activePatientInfo != null">{{ $t('Member') }}：{{ activePatientInfo.name }}</h4>
                    </div>
                    <div>
                        <vs-col vs-type="flex" vs-align="center">
                            <vs-input class="mr-2 w-auto" :class="windowWidth < 768 ? 'w-full' : ''" v-model="searchQuery" :placeholder="$t('searchAccount')" @keyup.enter.native="search_all_patient" />
                            <vs-button type="flat" radius icon="search" @click="search_all_patient"></vs-button>
                        </vs-col>
                    </div>
                </vs-col>
            </vs-row>
        </div>
        <div class="vx-row">
            <!-- 患部照片 -->
            <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
                <vx-card :title="$t('uploadPhoto')" id="div-with-loading">
                    <vs-row>
                        <!-- 備註 -->
                        <vs-col>
                            <vs-textarea :label="$t('doctorNote')" v-model="note" />
                        </vs-col>
                        <!-- 上傳的檔案名稱 -->
                        <vs-col class="mb-3" vs-type="flex" vs-justify="center" vs-align="center">
                            <p style="font-weight: bold">{{ uploadFileName0 }}</p>
                        </vs-col>
                    </vs-row>
                    <template slot="footer">
                        <div class="flex">
                            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
                                <!-- 選擇檔案 -->
                                <label class="cursor-pointer text-primary">
                                    <vs-col vs-type="flex" vs-align="center">
                                        <feather-icon class="mr-1" icon="FilePlusIcon"></feather-icon>
                                        <p>{{ $t('SelectFile') }}</p>
                                    </vs-col>
                                    <input type="file" name="images" id="images" ref="fileInput" multiple @change="onFileSelected($event, 0)" accept="image/*" hidden />
                                </label>
                                <!-- 上傳 -->
                                <vs-button class="px-4 py-2" icon-pack="feather" icon="icon-upload" :disabled="uploadbtn0 == false" @click="uploadImg(0)">
                                    {{ $t('UploadBtn') }}
                                </vs-button>
                            </vs-col>
                        </div>
                    </template>
                </vx-card>
            </div>
            <!-- 檢驗報告(PDF) -->
            <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
                <vx-card :title="$t('uploadReport')">
                    <vs-row>
                        <!-- 備註 -->
                        <vs-col>
                            <vs-textarea :label="$t('doctorNote')" v-model="note1" />
                        </vs-col>
                        <!-- 上傳的檔案名稱 -->
                        <vs-col class="mb-3" vs-type="flex" vs-justify="center" vs-align="center">
                            <p style="font-weight: bold">{{ uploadFileName1 }}</p>
                        </vs-col>
                    </vs-row>
                    <template slot="footer">
                        <div class="flex">
                            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
                                <!-- 選擇檔案 -->
                                <label class="cursor-pointer text-primary">
                                    <vs-col vs-type="flex" vs-align="center">
                                        <feather-icon class="mr-1" icon="FilePlusIcon"></feather-icon>
                                        <p>{{ $t('SelectFile') }}</p>
                                    </vs-col>
                                    <input type="file" name="reportToUpload" id="reportToUpload" ref="fileInput1" multiple @change="onFileSelected($event, 1)" accept="application/pdf" hidden />
                                </label>
                                <!-- 上傳 -->
                                <vs-button class="px-4 py-2" icon-pack="feather" icon="icon-upload" :disabled="uploadbtn1 == false" @click="uploadImg(1)">
                                    {{ $t('UploadBtn') }}
                                </vs-button>
                            </vs-col>
                        </div>
                    </template>
                </vx-card>
            </div>
            <!-- DICOM -->
            <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
                <vx-card :title="$t('uploadDICOM')">
                    <vs-row>
                        <!-- 備註 -->
                        <vs-col>
                            <vs-textarea :label="$t('doctorNote')" v-model="note2" />
                        </vs-col>
                        <!-- 上傳的檔案名稱 -->
                        <vs-col class="mb-3" vs-type="flex" vs-justify="center" vs-align="center">
                            <p style="font-weight: bold">{{ uploadFileName2 }}</p>
                        </vs-col>
                    </vs-row>
                    <template slot="footer">
                        <div class="flex">
                            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
                                <!-- 選擇檔案 -->
                                <label class="cursor-pointer text-primary">
                                    <vs-col vs-type="flex" vs-align="center">
                                        <feather-icon class="mr-1" icon="FilePlusIcon"></feather-icon>
                                        <p>{{ $t('SelectFile') }}</p>
                                    </vs-col>
                                    <input type="file" name="dicomToUpload" id="dicomToUpload" ref="fileInput2" multiple @change="onFileSelected($event, 2)" hidden />
                                </label>
                                <!-- 上傳 -->
                                <vs-button class="px-4 py-2" icon-pack="feather" icon="icon-upload" :disabled="uploadbtn2 == false" @click="uploadImg(2)">
                                    {{ $t('UploadBtn') }}
                                </vs-button>
                            </vs-col>
                        </div>
                    </template>
                </vx-card>
            </div>
            <!-- 眼底鏡圖片 -->
            <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
                <vx-card :title="$t('uploadFunduscope')">
                    <vs-row>
                        <!-- 選擇左右眼 -->
                        <vs-col class="mb-2">
                            <vs-radio v-model="position" vs-value="l" class="mr-2">{{ $t('LeftEyeLabel') }}</vs-radio>
                            <vs-radio v-model="position" vs-value="r">{{ $t('RightEyeLabel') }}</vs-radio>
                        </vs-col>
                        <!-- 備註 -->
                        <vs-col>
                            <vs-textarea :label="$t('doctorNote')" v-model="note3" />
                        </vs-col>
                        <!-- 上傳的檔案名稱 -->
                        <vs-col class="mb-3" vs-type="flex" vs-justify="center" vs-align="center">
                            <p style="font-weight: bold">{{ uploadFileName3 }}</p>
                        </vs-col>
                    </vs-row>
                    <template slot="footer">
                        <div class="flex">
                            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
                                <!-- 選擇檔案 -->
                                <label class="cursor-pointer text-primary">
                                    <vs-col vs-type="flex" vs-align="center">
                                        <feather-icon class="mr-1" icon="FilePlusIcon"></feather-icon>
                                        <p>{{ $t('SelectFile') }}</p>
                                    </vs-col>
                                    <input type="file" name="eyeToUpload" id="eyeToUpload" ref="fileInput3" @change="onFileSelected($event, 3)" hidden />
                                </label>
                                <!-- 上傳 -->
                                <vs-button class="px-4 py-2" icon-pack="feather" icon="icon-upload" :disabled="uploadbtn3 == false" @click="uploadImg(3)">
                                    {{ $t('UploadBtn') }}
                                </vs-button>
                            </vs-col>
                        </div>
                    </template>
                </vx-card>
            </div>
            <!-- 超音波照片 -->
            <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base" v-if="false">
                <vx-card :title="$t('uploadSuperSound')">
                    <vs-row>
                        <!-- 備註 -->
                        <vs-col>
                            <vs-textarea :label="$t('doctorNote')" v-model="note4" />
                        </vs-col>
                        <!-- 上傳的檔案名稱 -->
                        <vs-col class="mb-3" vs-type="flex" vs-justify="center" vs-align="center">
                            <p style="font-weight: bold">{{ uploadFileName4 }}</p>
                        </vs-col>
                    </vs-row>
                    <template slot="footer">
                        <div class="flex">
                            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
                                <!-- 選擇檔案 -->
                                <label class="cursor-pointer text-primary">
                                    <vs-col vs-type="flex" vs-align="center">
                                        <feather-icon class="mr-1" icon="FilePlusIcon"></feather-icon>
                                        <p>{{ $t('SelectFile') }}</p>
                                    </vs-col>
                                    <input type="file" name="ultrasoundToUpload" id="ultrasoundToUpload" ref="fileInput4" @change="onFileSelected($event, 4)" hidden />
                                </label>
                                <!-- 上傳 -->
                                <vs-button class="px-4 py-2" icon-pack="feather" icon="icon-upload" :disabled="uploadbtn4 == false" @click="uploadImg(4)">
                                    {{ $t('UploadBtn') }}
                                </vs-button>
                            </vs-col>
                        </div>
                    </template>
                </vx-card>
            </div>
            <!-- 心電圖 -->
            <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
                <vx-card :title="$t('uploadECG')">
                    <vs-row>
                        <!-- 備註 -->
                        <vs-col>
                            <vs-textarea :label="$t('doctorNote')" v-model="note5" />
                        </vs-col>
                        <!-- 上傳的檔案名稱 -->
                        <vs-col class="mb-3" vs-type="flex" vs-justify="center" vs-align="center">
                            <p style="font-weight: bold">{{ uploadFileName5 }}</p>
                        </vs-col>
                    </vs-row>
                    <template slot="footer">
                        <div class="flex">
                            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
                                <!-- 選擇檔案 -->
                                <label class="cursor-pointer text-primary">
                                    <vs-col vs-type="flex" vs-align="center">
                                        <feather-icon class="mr-1" icon="FilePlusIcon"></feather-icon>
                                        <p>{{ $t('SelectFile') }}</p>
                                    </vs-col>
                                    <input type="file" name="electrocardiogramToUpload" id="electrocardiogramToUpload" ref="fileInput5" @change="onFileSelected($event, 5)" hidden />
                                </label>
                                <!-- 上傳 -->
                                <vs-button class="px-4 py-2" icon-pack="feather" icon="icon-upload" :disabled="uploadbtn5 == false" @click="uploadImg(5)">
                                    {{ $t('UploadBtn') }}
                                </vs-button>
                            </vs-col>
                        </div>
                    </template>
                </vx-card>
            </div>
            <!-- 五官鏡 -->
            <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
                <vx-card :title="$t('uploadFace')">
                    <vs-row>
                        <!-- 選擇五官 -->
                        <vs-col class="mb-4">
                            <el-select class="w-full" v-model="facial">
                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                            </el-select>
                        </vs-col>
                        <!-- 備註 -->
                        <vs-col>
                            <vs-textarea :label="$t('doctorNote')" v-model="note6" />
                        </vs-col>
                        <!-- 上傳的檔案名稱 -->
                        <vs-col class="mb-3" vs-type="flex" vs-justify="center" vs-align="center">
                            <p style="font-weight: bold">{{ uploadFileName6 }}</p>
                        </vs-col>
                    </vs-row>
                    <template slot="footer">
                        <div class="flex">
                            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
                                <!-- 選擇檔案 -->
                                <label class="cursor-pointer text-primary">
                                    <vs-col vs-type="flex" vs-align="center">
                                        <feather-icon class="mr-1" icon="FilePlusIcon"></feather-icon>
                                        <p>{{ $t('SelectFile') }}</p>
                                    </vs-col>
                                    <input type="file" name="facialToUpload" id="facialToUpload" ref="fileInput6" @change="onFileSelected($event, facial)" hidden />
                                </label>
                                <!-- 上傳 -->
                                <vs-button class="px-4 py-2" icon-pack="feather" icon="icon-upload" :disabled="uploadbtn6 == false" @click="uploadImg(facial)">
                                    {{ $t('UploadBtn') }}
                                </vs-button>
                            </vs-col>
                        </div>
                    </template>
                </vx-card>
            </div>
            <!-- 一般影片 -->
            <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
                <vx-card :title="$t('uploadVideo')">
                    <vs-row>
                        <!-- 備註 -->
                        <vs-col>
                            <vs-textarea :label="$t('doctorNote')" v-model="note7" />
                        </vs-col>
                        <!-- 上傳的檔案名稱 -->
                        <vs-col class="mb-3" vs-type="flex" vs-justify="center" vs-align="center">
                            <p style="font-weight: bold">{{ uploadFileName7 }}</p>
                        </vs-col>
                    </vs-row>
                    <template slot="footer">
                        <div class="flex">
                            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
                                <!-- 選擇檔案 -->
                                <label class="cursor-pointer text-primary">
                                    <vs-col vs-type="flex" vs-align="center">
                                        <feather-icon class="mr-1" icon="FilePlusIcon"></feather-icon>
                                        <p>{{ $t('SelectFile') }}</p>
                                    </vs-col>
                                    <input type="file" name="reportToUpload" id="reportToUpload" ref="fileInput7" multiple @change="onFileSelected($event, 11)" accept=".mp4,.MP4" hidden />
                                </label>
                                <!-- 上傳 -->
                                <vs-button class="px-4 py-2" icon-pack="feather" icon="icon-upload" :disabled="uploadbtn7 == false" @click="uploadImg(11)">
                                    {{ $t('UploadBtn') }}
                                </vs-button>
                            </vs-col>
                        </div>
                    </template>
                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { searchpatient } from '@/api/user'
export default {
    watchQuery: ['account'],
    data() {
        return {
            eye: 'l',
            searchQuery: '',
            spid: '',
            note: '',
            note1: '',
            note2: '',
            note3: '',
            note4: '',
            note5: '',
            note6: '',
            note7: '',
            position: 'l',
            uploadfile: [],
            uploadbtn0: false,
            uploadbtn1: false,
            uploadbtn2: false,
            uploadbtn3: false,
            uploadbtn4: false,
            uploadbtn5: false,
            uploadbtn6: false,
            uploadbtn7: false,
            uploadFileName0: '',
            uploadFileName1: '',
            uploadFileName2: '',
            uploadFileName3: '',
            uploadFileName4: '',
            uploadFileName5: '',
            uploadFileName6: '',
            uploadFileName7: '',
            lodingfile: false,
            options: [
                {
                    value: '6',
                    label: this.$t('Ear'),
                },
                {
                    value: '7',
                    label: this.$t('Nose'),
                },
                {
                    value: '8',
                    label: this.$t('Throat'),
                },
                {
                    value: '9',
                    label: this.$t('Mouth'),
                },
                {
                    value: '10',
                    label: this.$t('Skin'),
                },
            ],
            facial: '6',
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        activePatientInfo() {
            if (this.$store.getters.activePatientInfo != null) {
                this.searchQuery = this.$store.getters.activePatientInfo.account
            }
            return this.$store.getters.activePatientInfo
        },
    },
    created() {
        // console.log('account',this.$route.query.account)
        if (typeof this.$route.query.account !== 'undefined') {
            this.searchQuery = this.$route.query.account
            this.search_all_patient()
        }
    },
    methods: {
        // 選擇檔案
        onFileSelected(e, type) {
            this.uploadfile = []
            if (e.target.files.length == 0) {
                this.uploadbtn0 = false
                this.uploadbtn1 = false
                this.uploadbtn2 = false
                this.uploadbtn3 = false
                this.uploadbtn4 = false
                this.uploadbtn5 = false
                this.uploadbtn6 = false
                this.uploadbtn7 = false
                this.uploadFileName0 = ''
                this.uploadFileName1 = ''
                this.uploadFileName2 = ''
                this.uploadFileName3 = ''
                this.uploadFileName4 = ''
                this.uploadFileName5 = ''
                this.uploadFileName6 = ''
                this.uploadFileName7 = ''
            } else {
                switch (type) {
                    case 0:
                        this.uploadbtn0 = true
                        this.uploadFileName0 = e.target.files[0].name
                        break
                    case 1:
                        this.uploadbtn1 = true
                        this.uploadFileName1 = e.target.files[0].name
                        break
                    case 2:
                        this.uploadbtn2 = true
                        this.uploadFileName2 = e.target.files[0].name
                        break
                    case 3:
                        this.uploadbtn3 = true
                        this.uploadFileName3 = e.target.files[0].name
                        break
                    case 4:
                        this.uploadbtn4 = true
                        this.uploadFileName4 = e.target.files[0].name
                        break
                    case 5:
                        this.uploadbtn5 = true
                        this.uploadFileName5 = e.target.files[0].name
                        break
                    case 11:
                        this.uploadbtn7 = true
                        this.uploadFileName7 = e.target.files[0].name
                        break
                    default:
                        this.uploadbtn6 = true
                        this.uploadFileName6 = e.target.files[0].name
                        break
                }
            }
            for (let index = 0; index < e.target.files.length; index++) {
                this.uploadfile.push(e.target.files[index])
            }
        },
        //查詢符合的患者
        search_all_patient() {
            this.$vs.loading()
            searchpatient({ account: this.searchQuery })
                .then((resp) => {
                    if (resp.data.status == 'ERROR') {
                        this.$vs.notify({ color: 'danger', title: this.$t('Error'), text: this.$t('PatientNotFind'), position: 'top-center' })
                    } else if (resp.data.status == 'NOT AUTHORIZE') {
                        this.$vs.notify({ color: 'danger', title: this.$t('Error'), text: this.$t('noThisPatientAuthorization'), position: 'top-center' })
                    } else {
                        this.$store.commit('SET_ACTIVE_PATIENT_INFO', resp.data.search_patient_info[0])
                    }
                })
                .then(() => {
                    this.$vs.loading.close()
                })
        },
        // 上傳
        uploadImg(type) {
            var _self = this
            const fd = new FormData()
            fd.append('type', type)
            if (this.$store.getters.activePatientInfo != null) {
                if (type == '0') {
                    fd.append('spid', this.$store.getters.activePatientInfo.pid)
                    fd.append('note', _self.note)
                } else if (type == '1') {
                    fd.append('spid', this.$store.getters.activePatientInfo.pid)
                    fd.append('note', _self.note1)
                } else if (type == '2') {
                    fd.append('spid', this.$store.getters.activePatientInfo.pid)
                    fd.append('note', _self.note2)
                } else if (type == '3') {
                    fd.append('spid', this.$store.getters.activePatientInfo.pid)
                    fd.append('position', _self.position)
                    fd.append('note', _self.note3)
                } else if (type == '4') {
                    fd.append('spid', this.$store.getters.activePatientInfo.pid)
                    fd.append('note', _self.note4)
                } else if (type == '5') {
                    fd.append('spid', this.$store.getters.activePatientInfo.pid)
                    fd.append('note', _self.note5)
                } else if (type >= 6 && type <= 10) {
                    fd.append('spid', this.$store.getters.activePatientInfo.pid)
                    fd.append('facialtype', _self.facial)
                    fd.append('note', _self.note6)
                } else if (type == '11') {
                    fd.append('spid', this.$store.getters.activePatientInfo.pid)
                    fd.append('note', _self.note7)
                }
                for (let index = 0; index < _self.uploadfile.length; index++) {
                    fd.append('image[]', _self.uploadfile[index])
                }
            } else {
                _self.$vs.dialog({
                    color: 'danger',
                    title: _self.$t('notifytitle'),
                    text: _self.$t('message.search_full_message'),
                })
                return false
            }
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
            }
            _self.$vs.loading({
                container: '#div-with-loading',
                scale: 0.6,
            })
            axios
                .post(process.env.VUE_APP_API_URL_HIS + '/api/uploadphoto/2', fd, config)
                .then(function (res) {
                    if (res.data.status == 'OK') {
                        var input = ''
                        _self.spid = ''
                        // 患部照片
                        if (type == '0') {
                            input = _self.$refs.fileInput
                            _self.note = ''
                            _self.uploadbtn0 = false
                            _self.uploadFileName0 = ''
                        }
                        // 檢驗報告(PDF)
                        else if (type == '1') {
                            input = _self.$refs.fileInput1
                            _self.note1 = ''
                            _self.uploadbtn1 = false
                            _self.uploadFileName1 = ''
                        }
                        // DICOM
                        else if (type == '2') {
                            input = _self.$refs.fileInput2
                            _self.note2 = ''
                            _self.uploadbtn2 = false
                            _self.uploadFileName2 = ''
                        }
                        // 眼底鏡圖片
                        else if (type == '3') {
                            input = _self.$refs.fileInput3
                            _self.note3 = ''
                            _self.uploadbtn3 = false
                            _self.uploadFileName3 = ''
                        }
                        // 超音波照片
                        else if (type == '4') {
                            input = _self.$refs.fileInput4
                            _self.note4 = ''
                            _self.uploadbtn4 = false
                            _self.uploadFileName4 = ''
                        }
                        // 心電圖
                        else if (type == '5') {
                            input = _self.$refs.fileInput5
                            _self.note5 = ''
                            _self.uploadbtn5 = false
                            _self.uploadFileName5 = ''
                        }
                        // 五官鏡
                        else if (type >= '6' || type <= '10') {
                            input = _self.$refs.fileInput6
                            _self.note6 = ''
                            _self.uploadbtn6 = false
                            _self.uploadFileName6 = ''
                        }
                        // 一般影片
                        else if (type == '11') {
                            input = _self.$refs.fileInput7
                            _self.note7 = ''
                            _self.uploadbtn7 = false
                            _self.uploadFileName7 = ''
                        }
                        input.type = 'text'
                        input.type = 'file'
                        _self.uplodingfile = false
                        _self.$vs.notify({ color: 'success', title: _self.$t('message.upload_success'), text: _self.$t('message.data_apdate_success'), position: 'top-center' })
                    } else {
                        _self.$vs.dialog({
                            color: 'danger',
                            title: _self.$t('notifytitle'),
                            text: _self.$t('message.upload_error'),
                        })
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log('upload file err ', error)
                    _self.$vs.dialog({
                        color: 'danger',
                        title: _self.$t('notifytitle'),
                        text: _self.$t('message.upload_file_error'),
                    })
                })
                .then(function () {
                    _self.$vs.loading.close('#div-with-loading > .con-vs-loading')
                })
        },
    },
    destroyed() {
        this.$store.commit('SET_ACTIVE_PATIENT_INFO', null)
    },
}
</script>
